@font-face {
  font-family: dotrice-bold-expanded;
  src: url(./fonts/dotrice.bold-expanded.otf);
}

/* Fonts from:  https://www.fontget.com/font/dotrice-family/ */
@font-face {
  font-family: dotrice;
  src: url(./fonts/dotrice-family_woff/dotrice.bold-condensed.woff);
  /* src: url(./fonts/dotrice-family_woff/dotrice.bold.woff); */
  /* src: url(./fonts/dotrice-family_woff/dotrice.expanded.woff); */
  /* src: url(./fonts/dotrice.bold.otf); */
}

body {
  
  background-color: rgb(48, 48, 48);
  background: linear-gradient(black, rgb(48, 48, 48));

}

.App {
  box-sizing: border;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-family: dotrice;
  font-size: 2vh;
  color: orange;
  padding: 5vh 0 ;
  overscroll-behavior-y:none ;
}

.container {
  display: flex;
  height: 100vh;
  flex-direction: inherit;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

input {
  text-align: center;
  background-color: black;
  color: white;
  padding: 1vh 2vh;
  margin: 1.75vh 0;
  border-radius: 50px;
  font-size: 3vh;
  font-family: dotrice;
  color: orange;
}

.row {
  display: flex;
  flex-direction: row;
}

/* ///////// Title ///////// */


.ball-save-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  font-size: 4vh;
  font-weight: 800;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: .4vh;
  text-align: center;
  background-color: rgb(163, 29, 29);
  border-radius: 50px;
  border-style: outset;
  border-top: solid 3px rgb(33, 33, 33);
  border-left: solid 1.5px rgb(33, 33, 33);
  border-bottom: solid 2px rgb(118, 118, 118);
  border-right: solid 1.5px rgb(118, 118, 118);

  width: 80vw;
}

/* ///////// Nav Box ///////// */
.nav-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  width: 80vw;
  background-color:  rgb(31, 28, 28);
  color: orange;
  font-family: dotrice;
  font-size: 3vh;
  margin: 2vh 0;
  padding-left: 2vh;
  padding-right: 3vh;
  border-radius: 25px;
  border-style: outset;
  border-top: solid 2px rgb(33, 33, 33);
  border-left: solid 2px rgb(33, 33, 33);
  border-bottom: solid 2px rgb(118, 118, 118);
  border-right: solid 2px rgb(118, 118, 118);
}

/* ///////// Button ///////// */
.add-btn {
  display: flex;
  position: absolute;
  width: 15vh;
  height: 15vh;
  justify-content: center;
  align-items: center;
  bottom: 10vh;
  right: 4vh;
  border: none;
  border-radius: 100px;
  background-color: rgb(31, 30, 30);
  box-shadow: 0px 20px 40px 0px black;
  font-size: 5vh;
  color: white;
}

/* ////////// Login Pinball Img /////////// */
.pinBall {
  display: flex;
  width: 15vh;
  height: 15vh;
  margin: 40px 30px;
  border-radius: 100px;
  padding: unset;
  border: unset;
  box-shadow: 0px 20px 40px 0px rgb(48, 48, 48);
  
}

/* ///////// Profile ///////// */

.profile {
  display: flex;
  flex-direction: column;
  color: white;
}


/* /////// ADD //////// */

.add-machine-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding-top: 5vh;
  color: white;
}
.add-title {
  display: flex;
  font-size: 4.5vh;
  width:60vw;
  justify-content: center;
  padding: 0 0 7vh 0;
}

.add-input-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;
  width: 35vh;
}

.label {
  font-size: 2.5vh;
  padding-bottom: 0vh;
}

.add-submit {
  margin-top: 0px;
}

/* ////////////// Login ////////////// */

.login-main-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;  
  color: orange;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: inherit;
  color: white;
}



.input-titles {
  font-family: dotrice;
  color: orange;
  font-size: 3vh;
}

/* ////////////// Loading /////////////// */

.loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* font-size: 10vh; */
	height: 10vh;
	width: 30vh;
	/* border: solid 1px #335; */
	/* border-radius: 5px; */
}

.loading:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4, end) 900ms infinite;
	animation: ellipsis steps(4, end) 900ms infinite;
	content: "\2026"; /* ascii code for the ellipsis character */
	width: 0px;
}


@keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

/* ////////////// History ////////////// */

.history-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 48vh;
  overflow: scroll;
  padding: 3vh 0 30vh 0;
}

.score-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 90vw;
  height: 15vh;
  font-family: dotrice;
  font-size: 2.5vh;
  color: orange;
  margin-left: 20px;
  padding: 10px 0;
}

.score-card-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  padding: 0 20px;
}
ul {
  padding-inline-start: unset;
  overflow: scroll;

}

.score-card-img {
  width: 15vh;
  height: 15vh;
  border-radius: 25px;
  border-top: solid 2px rgb(33, 33, 33);
  border-left: solid 2px rgb(33, 33, 33);
  border-bottom: solid 2px rgb(118, 118, 118);
  border-right: solid 2px rgb(118, 118, 118);
  
}


/* /////////// Hamburger Menu ////////// */

.hamburger-button {
  color:orange;
  background-color: rgb(31, 28, 28);
  border-style:none;
  font-size: 3vh;
  text-align: center;
}

.dropmenu {
  display: inline-block;
  position: relative;

  
}
.dropmenu-content {
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,1);
  
}
.dropmenu:hover .dropmenu-content {
  display: block;
  width: 20vh;
  
}
.dropmenu-content a {
  display: block;
  color: orange;
  padding: 5px;
  text-decoration: none;
  background-color: rgb(31, 28, 28);
 
}
.dropmenu-content a:hover {
  color: orangered;
  
}

/* /////////// Photo //////////// */

.photo-img {
  height: 4vh;
}

.photo-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 6vh;
  margin-left: 1vh;
  background-color: rgb(62, 58, 58);
  border-radius: 15px;
  border-bottom: solid 2px rgb(33, 33, 33);
  border-right: solid 2px rgb(33, 33, 33);
  border-top: solid 2px rgb(118, 118, 118);
  border-left: solid 2px rgb(118, 118, 118);
  
}

.score-input-imgButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 9vh;
}

.photo-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 85vh;
  
}

.add-photo-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.photo-capture {
  display: flex;
  width:fit-content;
  width: 80vw;
  height: 55vh;
  border-radius: 25px;
  border-top: solid 2px rgb(33, 33, 33);
  border-left: solid 2px rgb(33, 33, 33);
  border-bottom: solid 2px rgb(118, 118, 118);
  border-right: solid 2px rgb(118, 118, 118);
  
}

#icon-button-file {
  background-color: black;
}